@use "styles/mixins/setFonts" as *;
@use "styles/variables/colors/colors";

.hero {
  padding: 50px 0 100px 0;

  &__header {
    text-align: center;
    margin-bottom: 10px;
  }

  &__header-text {
    @include setFonts('Montserrat', 3.75rem, 4.375rem, 700);
    letter-spacing: -0.7px;
    color: colors.$defaultTextColor;
  }

  &__text-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("/common/vectors/tape.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 5% 20px;
  }

  &__text {
    font-family: 'Caveat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: clamp(3.5rem, 10vw, 12.5rem);
    line-height: clamp(3.5rem, 10vw, 12.5rem);;
    text-align: center;
    letter-spacing: 2.5px;
    text-transform: uppercase;
    color: colors.$defaultTextColor;
    max-width: 855px;
  }

  &__desc-wrapper {
    display: flex;
    justify-content: center;
    padding: 0 15px;
  }

  &__desc {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 2.8rem;
    line-height: 3.75rem;
    color: colors.$iconSuccess;
    text-align: center;
  }
}

@media screen and (max-width: 480px) {
  .hero {
    padding-bottom: 0;
    &__img-wrapper {
      margin-bottom: 0;
    }
    &__desc-wrapper {
      display: none;
    }
  }
}