@import "styles/mixins/useMedia";
@import "styles/variables/colors/colors";

.collapsible {
  max-width: 1060px;
  margin: 0 auto;

  &__activator {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: #000000;
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px -1px 0px 0px #e1e3e5 inset;
    border: none;
    // border-bottom: 1px solid #dadae0;
    // @include useMedia("max-width", 720px) {
    //   border-bottom: none;
    //   box-shadow: none;
    // }

    &-text {
      text-align: left;
      font-size: 1rem;
    }
  }

  &__arrow-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    margin-left: 15px;
    transform: rotate(270deg);
    transition: 200ms transform ease-in-out;
  }
  &__desc {
    font-size: 1rem;
    a {
      text-decoration: none;
      color: $primaryLinksBg;
    }
    :not(:last-child) {
      margin-bottom: 10px;
    }
    ul {
      list-style: disc;
      display: flex;
      flex-direction: column;
      // gap: 20px;
      margin-left: 20px;
    }
    :not(:last-child):not(li) {
      margin-bottom: 20px;
    }
    // ul,
    // ol:not(:last-child) {
    //   margin-bottom: 20px;
    // }
  }
}

.collapsible--active {
  .collapsible__arrow-wrapper {
    transform: rotate(360deg);
  }
}

.activator--reverse {
  flex-direction: row-reverse;
  justify-content: flex-end;

  .collapsible__arrow-wrapper {
    margin: 0 8px 0 0;
  }
}

.activator--text-highlight {
  text-decoration: none;
  border-color: currentColor;

  .collapsible__activator-text {
    color: inherit !important;
  }
}

.collapsible__desc-wrapper {
  padding: 30px;
}

.desc--reverse {
  padding-left: 50px;
}
