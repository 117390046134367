@use "styles/variables/colors/colors";

.experts {
  display: flex;
  justify-content: center;
  background-color: colors.$sectionPrimaryBg;
  padding: 50px 0;

  &__img-wrapper {
    margin: 15px 15px 15px 0;
  }

  &__container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1160px;
    width: 100%;
  }

  &__text {
    max-width: 460px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #000000;
    margin:15px;

  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0 15px;
  }

  &__auth-text {
    display: none;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 2rem;
    color: colors.$iconSuccess;
    text-align: center;
  }
}

.btn-wrapper {
  display: flex;
  align-items: center;
  max-width: 224px;
}

@media screen and (max-width: 480px) {
  .experts {
    background-color: transparent;

    &__auth-text {
      display: inline;
    }

    &__content {
      padding: 0 15px;
    }

    &__img-wrapper {
      margin: 0 0 20px 0;
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 45px 15px;
      background-color: colors.$sectionPrimaryBg;
    }
  }
}