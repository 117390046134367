@use "styles/mixins/setFonts" as *;
@use "styles/variables/colors/colors";

$textColor: #fff;

.about {
  padding: 50px 15px 160px;
  background-color: colors.$iconSuccess;

  &__header {
    max-width: 1140px;
    padding: 0 15px;
    margin: 0 auto min(145px, 10%) auto;

    &-text {
      @include setFonts("Roboto", 2.625rem, 2.75rem, 700);
      color: $textColor;
      text-align: center;
      margin-top: 30px;
    }
  }

  &__content {
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 90px;
  }

  &__footer {
    display: flex;
    justify-content: center;
  }
}

.about {
  &__list {
    display: flex;
    flex-direction: column;

    &:first-child {
      margin-right: 25px;

      .about__item-header {
        margin-right: 15px;
      }
    }

    &:last-child {
      margin-left: 25px;

      .about__item-header {
        margin-left: 15px;
      }
    }
  }

  &__item {
    max-width: 350px;
    min-height: 150px;
    text-align: right;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &-header {
      @include setFonts("Roboto", 1.625rem, 2rem, 400);
      color: $textColor;
      margin-right: 15px;
    }

    &-desc {
      @include setFonts("Roboto", 0.875rem, 1.25rem, 400);
      color: $textColor;
    }

    &-header-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
    }
  }
}

.about {
  &__img-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 182px);
    grid-gap: 15px;
  }

  &__img {
    * {
      width: 100% !important;
    }


    &--1 {
      grid-row: 1 / 3;
    }

    &--2 {
      grid-column: 2 / 4;
    }
  }
}

.item-left .about__item-header-wrapper {
  justify-content: flex-end;
}

.about__icon-wrapper .Polaris-Icon path {
  width: 20px;
}

.item-right.about__item {
  text-align: left;
}

.item-right .about__item-header {

  &:after {
    display: none;
  }

  &:before {
    content: '';
    position: absolute;
    top: calc(50% - 15px);
    left: 0;
    width: 30px;
    height: 30px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.Polaris-Icon svg {
  fill: #fff;
}


.about {
  &__content--mobile {
    display: none;
  }
}

.about__img-wrapper--mobile {
  margin-top: 40px;
  display: none;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;

  .about {
    &__img {
      display: flex;
    }
  }
}

@media screen and (max-width: 1170px) {
  .about__content {
    .about {
      &__img-wrapper {
        grid-template-columns: repeat(2, 182px);
      }

      &__img {
        &--1 {
          display: none;
        }

        &--2 {
          grid-column: 1/3;
        }

        &--5 {
          display: none;
        }
      }
    }
  }
}


@media screen and (max-width: 912px) {
  .about {
    &__img-wrapper {
      grid-template-columns: repeat(2, 140px);
    }
  }
}

@media screen and (max-width: 890px) {
  .about {
    padding-bottom: 60px;
  }
  .about__content {
    .about {
      &__img-wrapper {
        grid-template-columns: repeat(1, 140px);
      }

      &__img {
        &--2 {
          display: none;
        }

        &--4 {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .about__content {
    .about {
      &__img-wrapper {
        grid-template-columns: repeat(1, 140px);
      }

      &__img {
        &--2 {
          display: none;
        }

        &--4 {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .about {
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 660px) {
  .about {
    &__header {
      display: none;
    }

    &__content {
      display: none;
    }

    &__content--mobile {
      display: block;
    }

    &__img-wrapper--mobile {
      display: grid;
    }

    &__footer {
      display: none;
    }
  }
}

@media screen and (max-width: 560px) {
  .about {
    &__content {
      flex-direction: column;
    }

    &__item {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 480px) {
  .about__content {
    margin-bottom: 40px;
  }
}